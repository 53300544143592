.toggle-switch {
    width: 50px;
    height: 24px;
    border-radius: 12px;
    background-color: #ccc;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.toggle-switch.on {
    background-color: #007bff;
}

.toggle-knob {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    top: 2px;
    left: 2px;
    transition: left 0.3s ease;
}

.toggle-switch.on .toggle-knob {
    left: 28px;
}
